<template>
  <div>
    <b-card
      :title="editable ? 'Editar Área de trabalho' : 'Nova Áreas de trabalho'"
      class="text-center text-primary"
    >
      <validation-observer ref="formWorkspace">
        <!-- v-if="!disabledRadio && editable" :disabled="true" -->
        <b-form>
          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left mt-4 d-flex justify-content-center"
          >
            <!-- title -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="title"
                  class="text-center font-weight-bold text-primary"
                  >Título :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-form-input
                    id="worksapce_title"
                    v-model="modelWorkspace.workspace_title"
                    placeholder="Digite um título"
                  ></b-form-input>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- domain -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="domain"
                  class="text-center font-weight-bold text-primary"
                  >Domínio :</label
                >
                <b-form-input
                  id="domain"
                  v-model="modelWorkspace.domain"
                  placeholder="Digite um domínio"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <!-- document -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="document"
                  class="text-center font-weight-bold text-primary"
                  >CPF | CNPJ :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-form-input
                    v-if="modelWorkspace.document.length <= 14"
                    id="document"
                    v-model="modelWorkspace.document"
                    placeholder="Digite seu CPF ou CNPJ"
                    v-mask="'###.###.###-##'"
                  ></b-form-input>
                  <b-form-input
                    v-else
                    id="document"
                    v-model="modelWorkspace.document"
                    placeholder="Digite seu CPF ou CNPJ"
                    v-mask="'##.###.###/####-##'"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- phone -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="phone"
                  class="text-center font-weight-bold text-primary"
                  >Telefone :</label
                >
                <b-form-input
                  id="phone"
                  v-model="modelWorkspace.phone"
                  placeholder="Digite seu telefone"
                  v-mask="'(##)#####-####'"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <!-- project_code -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="project_code"
                  class="text-center font-weight-bold text-primary"
                  >Código de projetos :</label
                >
                <b-form-input
                  id="project_code"
                  v-model="modelWorkspace.project_code"
                  placeholder="Digite o código do projeto"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- contact -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="contact"
                  class="text-center font-weight-bold text-primary"
                  >Contato financeiro :</label
                >
                <b-form-input
                  id="financial_contact"
                  v-model="modelWorkspace.financial_contact"
                  placeholder="Digite seu contato financeiro"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <!-- email -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="email"
                  class="text-center font-weight-bold text-primary"
                  >Email :</label
                >
                <b-form-input
                  id="email"
                  v-model="modelWorkspace.email"
                  placeholder="Digite seu email de cobrança"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- zip_code -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="zip_code"
                  class="text-center font-weight-bold text-primary"
                  >CEP:</label
                >
                <b-form-input
                  id="cep"
                  v-model="modelWorkspace.cep"
                  placeholder="Digite seu email de cobrança"
                  v-mask="'#####-###'"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <!-- neighborhood -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="neighborhood"
                  class="text-center font-weight-bold text-primary"
                  >Bairro :</label
                >
                <b-form-input
                  id="neighborhood"
                  v-model="modelWorkspace.neighborhood"
                  placeholder="Digite o nome do seu bairro"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- street -->
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="street"
                  class="text-center font-weight-bold text-primary"
                  >Rua :</label
                >
                <b-form-input
                  id="street"
                  v-model="modelWorkspace.street"
                  placeholder="Digite o nome da sua rua"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <!-- number -->
            <b-col col xs="12" sm="12" md="2" lg="2">
              <b-form-group>
                <label
                  for="number"
                  class="text-center font-weight-bold text-primary"
                  >Número :</label
                >
                <b-form-input
                  id="number"
                  type="number"
                  v-model="modelWorkspace.number"
                  placeholder="Digite seu contato financeiro"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- state -->
            <b-col col xs="12" sm="12" md="5" lg="5">
              <b-form-group>
                <label
                  for="state"
                  class="text-center font-weight-bold text-primary"
                  >Estado :</label
                >
                <b-form-input
                  id="state"
                  v-model="modelWorkspace.state"
                  placeholder="Digite estado"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- city -->
            <b-col col xs="12" sm="12" md="5" lg="5">
              <b-form-group>
                <label
                  for="city"
                  class="text-center font-weight-bold text-primary"
                  >Cidade :</label
                >
                <b-form-input
                  id="city"
                  v-model="modelWorkspace.city"
                  placeholder="Digite a cidade que você mora"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="my-1 m-5 mb-3 d-flex justify-content-start">
            <!-- Prefixo -->
            <b-col
              col
              xs="12"
              sm="12"
              md="6"
              lg="6"
              class="justify-content-center d-flex"
            >
              <b-form-group v-slot="{ ariaDescribedby }">
                <label
                  for="contact"
                  class="text-center font-weight-bold text-primary pb-1"
                  >Prefixo :</label
                >
                <b-row>
                  <b-form-radio
                    class="mr-2"
                    v-model="modelWorkspace.prefix"
                    :aria-describedby="ariaDescribedby"
                    name="selected-prefix-yes"
                    value="true"
                    >Sim</b-form-radio
                  >
                  <b-form-radio
                    v-model="modelWorkspace.prefix"
                    :aria-describedby="ariaDescribedby"
                    name="selected-prefix-no"
                    value="false"
                    >Não</b-form-radio
                  >
                </b-row>
              </b-form-group>
            </b-col>
            <!-- disabled -->
            <b-col
              col
              xs="12"
              sm="12"
              md="6"
              lg="6"
              class="justify-content-center d-flex"
            >
              <b-form-group v-slot="{ ariaDescribedby }">
                <label
                  for="contact"
                  class="text-center font-weight-bold text-primary pb-1"
                  >Desativado :</label
                >
                <b-row>
                  <b-form-radio
                    class="mr-2"
                    v-model="modelWorkspace.disabled"
                    :aria-describedby="ariaDescribedby"
                    name="prefix"
                    value="true"
                    >Sim</b-form-radio
                  >
                  <b-form-radio
                    v-model="modelWorkspace.disabled"
                    :aria-describedby="ariaDescribedby"
                    name="disabled"
                    value="false"
                    >Não</b-form-radio
                  >
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="invoice-spacing mb-3" />
          <b-row class="d-flex p-4 justify-content-between">
            <!-- back -->
            <b-button
              :disabled="loading"
              variant="warning"
              class="mr-2"
              @click="$router.push('/workspaces')"
              >Voltar
            </b-button>
            <!-- inactivate -->
            <div class="d-flex justify-content-between">
              <b-button
                v-if="editable ? modelWorkspace : modelWorkspace.disabled"
                :variant="modelWorkspace.disabled ? 'success' : 'danger'"
                class="d-flex justify-content-center mr-2"
                @click="
                  modelWorkspace.disabled
                    ? (modalReative = true)
                    : (modalInactivate = true)
                "
                :disabled="modelWorkspace.workspace_title.length < 1 || loading"
              >
                <span
                  v-if="!loading"
                  id="modal-inactivate"
                  @click="
                    modelWorkspace.disabled
                      ? (modalInactivate = true)
                      : (modalReative = true)
                  "
                  >{{ modelWorkspace.disabled ? 'Reativar' : 'Inativar' }}</span
                >
                <b-spinner v-if="loading"></b-spinner>
              </b-button>

              <!-- updateWorkspace -->
              <b-button
                v-if="editable && !modelWorkspace.disabled"
                :variant="primary"
                @click="updateWorkspace"
                :disabled="modelWorkspace.workspace_title.length < 1 || loading"
              >
                <span v-if="!loading"> Salvar Alterações </span>
                <b-spinner class="spinner" v-if="loading"></b-spinner>
              </b-button>

              <!-- saveWorkspace -->
              <b-button
                v-if="!editable"
                variant="primary"
                @click="saveWorkspace"
                :disabled="modelWorkspace.workspace_title.length < 1 || loading"
              >
                <span v-if="!loading">Salvar</span>
                <b-spinner v-if="loading"></b-spinner>
              </b-button>
            </div>

            <!-- modalInativate -->
            <b-modal
              title="Inativar Área de Trabalho"
              no-close-on-backdrop
              hide-footer
              hide-header-close
              v-model="modalInactivate"
            >
              <b-row class="mt-1 justify-content-center d-flex text-center">
                <p>
                  Deseja realmente <strong>inativar</strong> esta Àrea de
                  trabalho <strong>{{ modelWorkspace.workspace_title }}</strong
                  >?
                </p>
              </b-row>

              <b-row class="justify-content-between d-flex mt-3 p-1">
                <!-- <p>Rodapé personalizado</p> -->
                <b-button variant="warning" @click="modalInactivate = false">
                  Cancelar
                </b-button>
                <b-button variant="primary" @click="inactivateWorkspace">
                  Confirmar
                </b-button>
              </b-row>
            </b-modal>

            <!-- modalReative -->
            <b-modal
              title="Reativar Área de Trabalho"
              no-close-on-backdrop
              hide-footer
              hide-header-close
              v-model="modalReative"
            >
              <b-row class="mt-1 justify-content-center d-flex text-center">
                <p>
                  Deseja realmente <strong>reativar</strong> esta Àrea de
                  trabalho <strong>{{ modelWorkspace.workspace_title }}</strong
                  >?
                </p>
              </b-row>

              <b-row class="justify-content-between d-flex mt-3 p-1">
                <!-- <p>Rodapé personalizado</p> -->
                <b-button variant="warning" @click="modalReative = false">
                  Cancelar
                </b-button>
                <b-button variant="primary" @click="reativeWorkspace">
                  Confirmar
                </b-button>
              </b-row>
            </b-modal>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'new-workspace',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    workspace_id: {
      Type: String,
      default: '',
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      modelWorkspace: {
        workspace_title: '',
        domain: '',
        project_code: '',
        document: '',
        phone: '',
        financial_contact: '',
        email: '',
        prefix: false,
        disabled: false,
        street: '',
        neighborhood: '',
        city: null,
        state: null,
        number: null,
        cep: null,
      },
      modalInactivate: false,
      modalReative: false,
      loading: false,
      required,
    };
  },
  created() {
    if (this.editable) {
      this.getWorkspace();
    }
  },
  methods: {
    async saveWorkspace() {
      await this.$refs.formWorkspace.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('saveWorkspace', this.modelWorkspace)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Área de trabalho cadastrado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'workspaces' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar àrea de trabalho',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    getWorkspace() {
      this.loading = true;
      let workspace_id = this.$route.params.id;

      this.$store
        .dispatch('getWorkspace', { id: workspace_id })
        .then((resp) => {
          this.loading = false;
          this.modelWorkspace = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar àrea de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateWorkspace() {
      this.$refs.formWorkspace.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateWorkspace', {
              ...this.modelWorkspace,
              id: this.$route.params.id,
            })
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Área de trabalho atualizada com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'workspaces' });
              }
            })
            .catch((err) => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${
                    err.response.data.message ==
                    'this workspace number of document is alredy exists'
                      ? 'CPF|CNPJ Já cadastrado em outra área de trabalho'
                      : err.response.data.message == 'Workspace not found'
                      ? 'Área de trabalho não encontrada'
                      : 'erro ao atualizar área de trabalho'
                  }`,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    reativeWorkspace() {
      this.loading = true;
      this.$store
        .dispatch('updateWorkspace', {
          ...this.modelWorkspace,
          disabled: false,
          id: this.$route.params.id,
        })
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.modalInactivate = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Área de trabalho inativada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'workspaces' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao inativa Área de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    inactivateWorkspace() {
      this.loading = true;
      this.$store
        .dispatch('updateWorkspace', {
          ...this.modelWorkspace,
          disabled: true,
          id: this.$route.params.id,
        })
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.modalInactivate = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Área de trabalho inativada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'workspaces' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao inativa Área de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
