<template>
  <b-card>
    <form-workspace :editable="editable" :workspace_id="$route.params.id"/>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import FormWorkspace from './FormWorkspace.vue';
export default {
    components: {
        BCard,
        FormWorkspace
    },
    data(){
        return{
        editable: true
    }}
}
</script>

<style>

</style>